import { Component, Input, OnInit } from '@angular/core';
import { PgFormField } from '../../models/form.model';

@Component({
    selector: 'app-pg-detail-field',
    templateUrl: './pg-detail-field.component.html',
    styleUrls: ['./pg-detail-field.component.scss']
})

export class PgDetailFieldComponent implements OnInit {
    
    @Input() fieldData: PgFormField;

    @Input() value:any;

    @Input() placeholder:string;

    ngOnInit(): void {
    }
}
