import { DataService } from '../services/data.service';
import { Observable } from 'rxjs';

export type PgFileType = 'documents'|'images'|'videos'|'audios'

export class PgFileUtils {
    
    private static _fileExtensionsByCategory = {
        'images': ['jpeg','jpg','png','gif','svg','webp','tif','tiff','nef'],
        'audios': ['mp3','aac','ogg'],
        'videos': ['mp4','mpeg','mov'],
        'documents': ['doc','docx','pdf','xls','xlsx','zip','gpx','p7m','txt','html']
    }

    static getCategoryExtensions(type:PgFileType) {
        return this._fileExtensionsByCategory[type]
    }

    static doesExtensionMatch(type:PgFileType, fileName:string) {
        fileName = fileName.toLowerCase()

        for(let i in this._fileExtensionsByCategory) {
            if(type == null || i == type) {
                for(let _extension of this._fileExtensionsByCategory[i]) {
                    if(fileName.endsWith('.' + _extension)) return true;
                }
            }
        }
    }

    static getFileAcceptString(type:PgFileType) {
        let _retVal = ''

        for(let i in this._fileExtensionsByCategory) {
            if(type == null || i == type) {
                for(let _extension of this._fileExtensionsByCategory[i]) {
                    if(_retVal != '') _retVal += ','
                    _retVal += '.' + _extension
                }
            }
        }

        if(_retVal == '') return null;
        else return _retVal;
    }

    static getCategoryIcon(type:PgFileType) {
        switch(type) {
            case 'images': return 'fa-regular fa-image';
            case 'audios': return 'fa-regular fa-volume-up';
            case 'videos': return 'fa-regular fa-video';
            case 'documents': return 'fa-regular fa-file-alt';
            default: return 'fa-regular fa-asterisk';
        }
    }

    static getFileIcon(type:PgFileType, fileName:string) {
        switch(type) {
            case 'documents': {
                let _cExtension = '';

                let _cExtensionMatch = fileName.match(/\.[^\.]*$/);

                if(_cExtensionMatch != null) {
                    _cExtension = _cExtensionMatch[0].substr(1).toLowerCase();
                }

                switch(_cExtension) {
                    case 'doc': 
                    case 'docx': 
                        return 'fa-regular fa-file-word'; 

                    case 'ppt': 
                    case 'pptx': 
                        return 'fa-regular fa-file-powerpoint'; 

                    case 'xls': 
                    case 'xlsx': 
                        return 'fa-regular fa-file-excel'; 
    
                    case 'pdf': 
                        return 'fa-regular fa-file-pdf'; 

                    case 'gpx': 
                        return 'fa-regular fa-file-code'; 

                    default:
                        return 'fa-regular fa-file-alt'; 
                }
            }
 
            case 'images': 
                return 'fa-regular fa-file-image'; 

            case 'audios': 
                return 'fa-regular fa-file-audio'; 

            case 'videos': 
                return 'fa-regular fa-file-video'; 
            default: 
                return 'fa-regular fa-file'; 
        }
    }

    static fileSizeUnits = ['B', 'KB', 'MB', 'GB', 'TB']

    static getFileSizeString(val:number) {
        let _unit = this.fileSizeUnits[0];
        
        for(let i = 1; i < this.fileSizeUnits.length; i++) {
            if(val > 1024) {
                val /= 1024;
                _unit = this.fileSizeUnits[i]
            }
            else {
                break;
            }
        }

        return (Math.round(val * 10) / 10) + ' ' + _unit;
    }

    static getFileSizeNumber(val:string) {
        let _retVal:number = null;

        let _cleanString = val.replace(/\s*/g, '');
        let _numericPart = _cleanString.match(/^\d*/);
        let _unitPart = _cleanString.match(/[^\d]*$/);

        if(_numericPart != null) {
            _retVal = parseInt(_numericPart[0]);
        }

        if(_unitPart != null) {
            let _cUnit = _unitPart[0].toUpperCase();
            let _cUnitIndex = this.fileSizeUnits.indexOf(_cUnit);

            if(_cUnitIndex) {
                _retVal *= Math.pow(1024, _cUnitIndex)
            }
        }

        return _retVal;
    }
}

export class PgDirectoryData {
    id:string;
    parent_id:string;
    label:string;

    path:string;
    user_id:string;
    group_id:string;
    realm_id:string;
}

export class PgPostFileData {
    group_id?:string = null;
    realm_id?:string = null;

    directory_id?:string = null;

    tags?:string = null;
    system_tags?:string = null;
    license?:string = null;
    categories?:string = null;
}

export class PgFileData extends PgPostFileData {
    nome_file:string = null;

    id?:string = null;
    nome?:string = null;
    url?:string = null;
    type?:PgFileType = null;
    //category?:string = null;

    user_id?:string = null;
}

export class PgFile extends PgFileData {
    icon:string = null;
    progress:number = null;
    error:boolean = false;
    deleted:boolean = false;

    constructor(fromData?:PgFileData) {
        super();

        if(fromData != null) {
            for(let i in fromData) {
                this[i] = fromData[i];
            }

            this.icon = PgFileUtils.getFileIcon(this.type, this.nome_file)
        }
    }
}

export class PgFileResource {
    constructor(private dataService:DataService, relatedResource?:string, relatedElement?:string) {
        if(relatedResource != null) this.relatedResource = relatedResource;
        if(relatedElement != null) this.relatedElement = relatedElement;

        console.log('TODO: ripristinare relatedResource, relatedElement')

        this.relatedResource = null;
        this.relatedElement = null;
    }

    relatedResource:string = null;
    relatedElement:string = null;

    list:Array<PgFile> = null;
    fileById:{[id:string]: PgFile} = {};

    isLoading = false;

    load() {
        let _retObserver = null;

        if(!this.isLoading) {
            this.isLoading = true;

            // TODO: gestire meglio il no-limit?
            this.dataService.getResourceData('File', { limit: 5000 }, this.relatedResource, this.relatedElement).subscribe((data) => {
                this.list = [];
                this.fileById = {};
    
                for(let _cData of data) {
                    this.list.push(new PgFile(_cData));
                    this.fileById[_cData.id] = _cData;
                }
    
                this.isLoading = false;

                if(_retObserver != null) {
                    _retObserver.next();
                    _retObserver.unsubscribe();
                }
            })
        }

        return new Observable<any>((observer) => {
            _retObserver = observer;
        })
    }
}