import { Component, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Subscription } from 'rxjs';
import { NotificationsService, PGNotification } from '../../services/notifications.service';
import { DataService } from '../../services/data.service';
import { PgNotificationComponent } from '../../pg-ui-elements/pg-notification/pg-notification.component';

@Component({
  selector: 'app-pg-notification-list',
  templateUrl: './pg-notification-list.component.html',
  styleUrls: ['./pg-notification-list.component.scss']
})
export class PgNotificationListComponent implements OnInit, OnDestroy {
    currentNotifications:Array<PGNotification> = null;

    notificationsHistory:Array<PGNotification> = null;
    historyLimit = 10;
    historyPage = -1;
    isHistoryAtEnd = false;
    isLoadingHistory = false;

    private _notificationServiceSubscription:Subscription;

    constructor(private notificationsService:NotificationsService, private dataService:DataService) { }

    ngOnInit(): void {
        this.currentNotifications = [];
        
        for(let _notification of this.notificationsService.notifications) {
            if(!_notification.local) {
                this.currentNotifications.push(_notification)
            }
        }

        this._notificationServiceSubscription = this.notificationsService.onNotification.subscribe((change) => {
            if(change.operation == 'add') {
                if(!change.notification.local) {
                    this.currentNotifications.unshift(change.notification)
                    this.resetHistory(true);
                }
            }
            else if(change.operation == 'del') {
                for(let i = 0; i < this.currentNotifications.length; i++) {
                    if(this.currentNotifications[i].id == change.notification.id) {
                        this.currentNotifications.splice(i, 1);
                        this.resetHistory(true);
                        break;
                    }
                }
            }
        })

        this.resetHistory();
    }

    private _resetHistoryTimeout = null;

    resetHistory(withDelay?:boolean) {
        this.notificationsHistory = [];
        this.historyPage = -1;
        this.isHistoryAtEnd = false;
        this.isLoadingHistory = true;

        clearTimeout(this._resetHistoryTimeout);

        if(withDelay) {
            this._resetHistoryTimeout = setTimeout(() => {
                this.checkLoadHistory();
            }, 1000)
        }
        else {
            this.checkLoadHistory();
        }
    }

    checkLoadHistory() {
        if(!this.isHistoryAtEnd) {
            this.historyPage++;
            this.isLoadingHistory = true;

            this.dataService.getNotifications({ 
                offset: this.historyPage * this.historyLimit,
                limit: this.historyLimit + 1,
                filter: [
                    { field: 'status', operator: '==', value: ['read'] }
                ]
            }).subscribe((data) => {
                this.isLoadingHistory = false;
                if(data.length < this.historyLimit + 1) this.isHistoryAtEnd = true;
    
                for(let i = 0; i < this.historyLimit && i < data.length; i++) {
                    this.notificationsHistory.push(new PGNotification(data[i]))
                }
            }, () => {
                this.isHistoryAtEnd = true;
                this.isLoadingHistory = false;
            })
        }
    }

    ngOnDestroy() {
        if(this._notificationServiceSubscription != null) this._notificationServiceSubscription.unsubscribe();
    }

    @ViewChildren(PgNotificationComponent) notificationComponents:QueryList<PgNotificationComponent>

    markAsRead() {
        let _markAsRead:Array<string> = [];

        for(let _notification of this.currentNotifications) {
            if(_notification.status != 'read') {
                _markAsRead.push(_notification.id)
            }
        }

        this.notificationComponents.forEach((item) => {
            if(item.childNotifications != null) {
                for(let _notification of item.childNotifications) {
                    if(_notification.status != 'read') {
                        _markAsRead.push(_notification.id)
                        _notification.status = 'read'
                    }
                }
            }
        })

        if(_markAsRead.length > 0) {
            this.notificationsService.markAsRead(_markAsRead)
        }
    }
}
