<div class="FormsExperience">
    <app-pg-loading *ngIf="isSaving || isLoading" [isOverlay]="true" [isLarge]="true"></app-pg-loading>
    <div *ngIf="!isLoading">
        <ng-container *ngIf="showImport">
            <app-forms-template-import (setTemplateData)="setTemplateData($event)"></app-forms-template-import>
        </ng-container>
        <div *ngIf="!showImport">
            <app-forms-main [notFound]="notFound" [inModal]="inModal" [importData]="importData" [formId]="formId" [formFlow]="formFlow" [flowState]="flowState" (flowStateChange)="onFlowStateChange($event)" [returnUrl]="returnUrl" [viewOnly]="viewOnly" (valuesChange)="onValuesChange($event)" (saveData)="onSaveData($event)" (deleteData)="onDeleteData($event)" (resetForm)="onResetForm()" (cancelEdit)="onCancelEdit()"></app-forms-main>
        </div>
    </div>
</div>

<ng-template #createExperienceSupplierModal>
    <div class="modal-body">
        <app-forms-experience-supplier [elementId]="createModalData.id" [inModal]="true" (formReturn)="onCreateExperienceSupplierModalReturn($event)"></app-forms-experience-supplier>
    </div>
</ng-template>
