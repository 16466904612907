import { Component, Input, OnChanges, EventEmitter, Output, OnInit } from '@angular/core';
import { PgSelectOption } from '../../pg-ui-controls/pg-select/pg-select.component';
import { OptionMapsService } from '../../services/option-maps.service';
import { PgFilterStatus } from '../../services/data.service';
import { PGUtilities } from '../../pg-utilities';


@Component({
  selector: 'app-pg-filter-editor-search',
  templateUrl: './pg-filter-editor-search.component.html',
  styleUrls: ['./pg-filter-editor-search.component.scss']
})
export class PGFilterEditorSearch implements OnInit, OnChanges {

    @Input() resourceId:string;
 
    @Input() status:PgFilterStatus
    @Input() appliedFilter:PgFilterStatus

    @Input() hasTags:boolean;
    @Input() hasSystemTags:boolean;

    @Input() categoriesOptions:Array<PgSelectOption>;
    @Input() categoriesField:string;

    @Output() applySearch = new EventEmitter<void>();

    systemTagsOptions:Array<PgSelectOption> = null;

    constructor(private optionMapsService:OptionMapsService) { }

    ngOnInit() {
        this.systemTagsOptions = this.optionMapsService.getFixedOptionMap('*', 'system_tags')
    }

    ngOnChanges(simpleChanges:any) {
        if(this.categoriesField == null) this.categoriesField = 'category'
    }

    hasAppliedSearch() {
        return this.appliedFilter?.search != null;
    }

    /*onSearchKeyUp(event:KeyboardEvent) {
        if(event.keyCode == 13) {
            this.applySearch.emit()
        }
    }*/

    hasAppliedTags() {
        return this.appliedFilter?.tags != null;
    }
    
    hasAppliedSystemTags() {
        return this.appliedFilter?.system_tags != null;
    }

    private _getCategoriesFilter() {
        for(let _filter of this.appliedFilter.filter) {
            if(_filter.resource == this.resourceId &&  _filter.field == this.categoriesField && _filter.operator == 'in') {
                return _filter;
            }
        }
    }

    hasAppliedCategories() {
        return this._getCategoriesFilter() != null;
    }

    getFilterCategories() {
        let _categoriesFilter = this._getCategoriesFilter()
        if(_categoriesFilter?.value != null) return JSON.stringify(_categoriesFilter.value)
    }

    setFilterCategories(val:string) {
        let _value:Array<string> = PGUtilities.tryParseJSON(val);

        for(let i = this.status.filter.length - 1; i >= 0; i--) {
            if(this.status.filter[i].field == this.categoriesField) {
                this.status.filter.splice(i, 1);
            }
        }

        this.status.filter.push({
            resource: this.resourceId,
            field: this.categoriesField,
            operator: 'in',
            value: _value
        })

        this.applySearch.emit();
    }
}
