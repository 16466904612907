<div class="EFProductCard card {{ vertical ? 'EFProductCard--Vertical mb-3': '' }} {{ fill ? 'EFProductCard--Fill': '' }} {{ disabled ? 'EFProductCard--Disabled': '' }}" (click)="onCardClick()">
    <ng-container *ngIf="productData && productData.error">
        <div class="card-body text-center">
            <div class="alert alert-danger">
                <div class="mb-3">
                    <i class="fa-regular fa-exclamation-triangle fa-3x"></i>
                </div>
                <div>
                    {{ 'experience-finder.general.product-error' | locTranslate }}
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="productData && !productData.error">
        <div class="EFProductCard-Image">
            <div class="card-img-top">
                <div class="EFProductCard-Image-Background bg-light text-white d-flex justify-content-center align-items-center">
                    <i class="fa-regular fa-image fa-8x"></i>
                </div>
                <div *ngIf="getCoverImage(); let image">
                    <app-pg-image-autosize [src]="image" fit="cover"></app-pg-image-autosize> <!-- TODO: sarebbe da mettere qualcosa di trasparente per mantenere l'immagine col placeholder? -->
                </div>
            </div>
        </div>
        <div class="EFProductCard-Content">
            <div class="card-body">
                <div class="EFProductCard-Info EFProductCard-Info--Header">
                    <div>
                        <span *ngIf="getProductDuration(); let text">
                            <i class="fa-regular fa-stopwatch"></i>
                            <small class="ms-2">{{text}}</small>
                        </span>

                        <span *ngIf="productData.stars; let stars">
                            <small>
                                <ng-container *ngFor="let i of [1,2,3,4,5]">
                                    <i *ngIf="i <= stars" class="fa-solid fa-star text-warning opacity-100"></i>
                                    <i *ngIf="i > stars" class="fa-regular fa-star text-muted opacity-50"></i>
                                </ng-container>
                            </small>
                        </span>
                    </div>

                    <div>
                        <small *ngIf="getCostDisplay(); let cost" [innerHTML]="cost"></small>
                    </div>
                </div>
                <div class="EFProductCard-Content-Inner mb-2">
                    <h3 class="card-title h5 pb-2 mb-2">
                        <div *ngIf="!productData.published" class="float-end"> 
                            <i class="fa-regular fa-eye-slash"></i>
                        </div>
                        <span [innerHTML]="productData.getTranslatedProductField('title')"></span>
                    </h3>

                    <!--<div class="card-text text-muted" [innerHTML]="productData.getTranslatedProductField('description') | safe:'html'"></div>-->
                </div>

                <div class="EFProductCard-Info EFProductCard-Info--Header">
                    <div>
                        <span *ngIf="productData.getDateDisplay(); let date">
                            <i class="fa-regular fa-calendar"></i>
                            <small class="ms-2">{{date}}</small>
                        </span>
                    </div>
                    
                    <div>
                        <small *ngIf="productData.mean_of_transport; let transport" class="EFProductCard-Info-MeanOfTransport">
                            <ng-container *ngFor="let item of transport">
                                <i *ngIf="item == 'walking'" class="ms-1 fa-regular fa-lg fa-walking"></i>
                                <i *ngIf="item == 'bicycling'" class="ms-1 fa-regular fa-lg fa-biking"></i>
                                <i *ngIf="item == 'driving'" class="ms-1 fa-regular fa-lg fa-car"></i>
                                <i *ngIf="item == 'motorcycle'" class="ms-1 fa-regular fa-motorcycle"></i>
                                <i *ngIf="item == 'public_transport'" class="ms-1 fa-regular fa-bus"></i>
                            </ng-container>
                        </small>
                    </div>
                </div>

                <div class="EFProductCard-Info EFProductCard-Info--Footer">
                    <span class="badge text-white" [style.backgroundColor]="getTypeColor(productData.type)">
                        <i class="fa-regular" [innerHTML]="getTypeIcon(productData.type)"></i>
                        <span class="ms-2">{{productData.getTypeText()}}</span>
                    </span>

                    <span *ngIf="productData.getCategoryText(); let text" class="badge bg-white border ms-2" [style.color]="getTypeColor(productData.type)" [style.borderColor]="getTypeColor(productData.type) + ' !important'">
                        <span [innerHTML]="text"></span>
                    </span>
                </div>
            </div>
        </div>
    </ng-container>
</div>