<div class="PGNotificationList">
    <div *ngIf="!isLoadingHistory && currentNotifications.length == 0 && notificationsHistory.length == 0" class="text-muted">
        Nessuna notifica
    </div>
    <div *ngFor="let notification of currentNotifications" class="mb-3">
        <app-pg-notification [notification]="notification"></app-pg-notification>
    </div>
    <div *ngFor="let notification of notificationsHistory" class="mb-3">
        <app-pg-notification [notification]="notification"></app-pg-notification>
    </div>
    <div *ngIf="isLoadingHistory">
        <app-pg-loading></app-pg-loading>
    </div>
    <app-pg-view-more [hidden]="isLoadingHistory || isHistoryAtEnd" (viewMore)="checkLoadHistory()"></app-pg-view-more>
</div>
