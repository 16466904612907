import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EFProduct } from '../../../models/experience.model';
import { LocalizationService } from '../../../services/localization.service';
import { MapStylesService } from '../../../services/map-styles.service';
import { EnvironmentService } from '../../../services/environment.service';

@Component({
  selector: 'app-ef-product-card',
  templateUrl: './ef-product-card.component.html',
  styleUrls: ['./ef-product-card.component.scss']
})
export class EfProductCardComponent implements OnInit {

    constructor(private environmentService:EnvironmentService, private localizationService:LocalizationService, private mapStylesService:MapStylesService) { }

    @Input() productData:EFProduct;
    @Input() vertical:boolean;
    @Input() fill:boolean;
    @Input() disabled:boolean;

    @Output() showProduct = new EventEmitter<string>();

    ngOnInit(): void {
    }

    getTranslatedProperty(product:EFProduct, prop:string) {
        return product.getTranslatedProductField(prop)
    }

    formatDate(date:Date) {
        return this.localizationService.format.date(date, true)
    }

    getTypeColor(type:string) {
        if(this.mapStylesService.markerStyles[type] != null) {
            return this.mapStylesService.markerStyles[type].icon.fillColor;
        }
    }
    
    getTypeIcon(type:string) {
        if(this.mapStylesService.markerStyles[type] != null) {
            return this.mapStylesService.markerStyles[type].label.text;
        }
    }

    getProductDuration() {
        return this.localizationService.format.duration(this.productData.duration || this.productData.travel_time);
    }

    getCoverImage() {
        if(this.productData.resource == 'eatery' || this.productData.resource == 'host' || this.productData.resource == 'experienceSupplier' || this.productData.resource == 'poi') {
            return this.environmentService.environment.APIUrl + '/public/' + this.localizationService.getCleanResourceId(this.productData.resource) + '/' + this.productData.element + '/cover'
        }
        else {
            if(this.productData.cover != null && this.productData.cover != '') return this.productData.cover;
            else if(this.productData.images != null && this.productData.images[0] != null && this.productData.images[0] != '') return this.productData.images[0];
        }
    }

    getCostDisplay() {
        if(this.productData.cost_list != null) {
            if(this.productData.cost_list.length > 1) {
                let _min = this.productData.cost_list[0].toString();
                let _max = this.productData.cost_list[this.productData.cost_list.length - 1].toString();

                if(_min == '0') {
                    if(_max == '0') {
                        return this.localizationService.translate('experience-finder.general.price-free')
                    }
                    else {
                        _min = this.localizationService.translate('experience-finder.general.price-free')
                    }
                }

                return this.localizationService.translate('experience-finder.general.from-to', { from: _min, to: _max }) + ' &euro;'
            }
            else {
                if(this.productData.cost_list[0] == 0) return this.localizationService.translate('experience-finder.general.price-free')
                else return this.productData.cost_list[0] + ' &euro;'
            }
        }
        
    }

    onCardClick() {
        this.showProduct.emit(this.productData.id)
    }
}
