import { Component, Input, OnChanges } from '@angular/core';
import { DataService, UserLabel } from '../../services/data.service';
import { PGUtilities } from '../../pg-utilities';

@Component({
  selector: 'app-pg-user-widget',
  templateUrl: './pg-user-widget.component.html',
  styleUrls: ['./pg-user-widget.component.scss']
})
export class PgUserWidgetComponent implements OnChanges {

    @Input() userId:string;
    @Input() userData:UserLabel;

    constructor(private dataService:DataService) { }

    isLoading = false;

    ngOnChanges(): void {
        if(this.userData == null) {
            this.isLoading = true;

            this.dataService.getElementData('User', this.userId).subscribe((data) => {
                this.isLoading = false;
                this.userData = data;
            }, () => {
                this.isLoading = false;
            })
        }
    }

    formatUserLabel() {
        return PGUtilities.formatUserLabel(this.userData, true)
    }

}
