<div class="PGFilterEditor container-fluid">

    <ng-container *ngIf="!adminMode">
        <app-pg-filter-editor-search [resourceId]="resourceId" [status]="status" [appliedFilter]="appliedFilter" [hasTags]="hasTags" [hasSystemTags]="hasSystemTags" [categoriesField]="categoriesField" [categoriesOptions]="categoriesOptions" (applySearch)="doApplySearch(true)"></app-pg-filter-editor-search>
    
        <div *ngIf="canFilterSomething && !disableAdvancedFilters" class="row mt-2">
            <div class="col-12 PGFilterEditor-Advanced">
                <button class="btn btn-sm {{hasAdvancedFilters ? 'btn-primary' : 'btn-secondary'}}" (click)="toggleAdvancedFilters()">
                    <i class="fa-regular fa-filter fa-fw"></i><span>{{ 'pg-filter-editor.advanced-filters' | locTranslate }}</span>
                </button>
            </div>        
        </div>
    </ng-container>

    <div *ngIf="!editFilter && hasAppliedFilter()" class="row">
        <div class="col PGFilterEditor-Applied">
            <h4 class="mb-0 mt-2">{{ 'pg-filter-editor.section-filter' | locTranslate }}</h4>
            <ng-container *ngFor="let byResource of appliedFilterByResource">
                <div *ngIf="byResource.id != resourceId" class="PGFilterEditor-Applied-Resource">{{byResource.name}}</div>
                <div>{{getResourceFilterString(byResource)}}</div>
            </ng-container>
        </div>
    </div>
    <div *ngIf="!editOrder && hasAppliedOrder()" class="row">
        <div class="col PGFilterEditor-Applied">
            <h4 class="mb-0 {{ hasAppliedFilter() ? 'mt-0' : 'mt-2' }}">{{ 'pg-filter-editor.section-order' | locTranslate }}</h4>
            <div>{{getOrderString(appliedOrder)}}</div>
        </div>
    </div>
    <div *ngIf="editFilter" class="row">
        <div class="col">
            <h4 class="mt-3 mb-2">{{ 'pg-filter-editor.section-filter' | locTranslate }}</h4>
            <div class="container-fluid py-2 my-2 border-top border-bottom">
                <div class="PGFilterEditor-Header row pb-1">
                    <div class="col PGFilterEditor-Element-Field">{{ 'pg-filter-editor.filter-field' | locTranslate }}</div>
                    <div class="col PGFilterEditor-Element-Operator">{{ 'pg-filter-editor.filter-operator' | locTranslate }}</div>
                    <div class="col PGFilterEditor-Element-Value">{{ 'pg-filter-editor.filter-value' | locTranslate }}</div>
                    <div class="col PGFilterEditor-Element-Actions"></div>
                </div>
                <ng-container *ngFor="let byResource of filterByResource">
                    <div *ngIf="byResource.id != resourceId" class="PGFilterEditor-Resource row border-bottom">
                        <div class="col">{{byResource.name}}</div>
                    </div>
                    <div *ngFor="let filter of byResource.list" class="PGFilterEditor-Element row pb-1">
                        <div class="col PGFilterEditor-Element-Field">
                            <app-pg-select [options]="configByResource[byResource.id].fieldOptions" [display]="{ oneLine: true }" 
                            [readonly]="filter.readonly" [(ngModel)]="filter.field" (ngModelChange)="onFilterFieldChange(filter)"></app-pg-select>
                        </div>
                        <div class="col PGFilterEditor-Element-Operator">
                            <app-pg-select *ngIf="filter.field != null" [options]="configByResource[byResource.id].operatorOptionsByField[filter.field]" [display]="{ oneLine: true }" 
                            [readonly]="filter.readonly" [(ngModel)]="filter.operator" (ngModelChange)="onFilterOperatorChange(filter)"></app-pg-select>
                        </div>
                        <div class="col PGFilterEditor-Element-Value">
                            <div *ngIf="filter.field != null && filter.operator != null">
                                <app-pg-form-field *ngFor="let valueField of valueFields[getFilterIndex(filter)]; let i = index"
                                [fieldData]="valueField" [noLabel]="true" [display]="{ oneLine: true }"
                                [(ngModel)]="filter.value[i]" (ngModelChange)="onFilterValueChange(filter)"></app-pg-form-field>
                            </div>
                        </div>
                        <div class="col PGFilterEditor-Element-Actions">
                            <button class="btn btn-sm btn-secondary" [hidden]="filter.readonly" (click)="removeFilter(filter)">
                                <i class="fa-regular fa-trash"></i>
                            </button>
                        </div>
                    </div>
                </ng-container>
                <div class="row">
                    <div class="PGFilterEditor-Footer col">
                        <span>{{ 'pg-filter-editor.add-filter' + (hasMultipleResourceOptions ? '-on' : '') | locTranslate }}</span>
                        <app-pg-select *ngIf="hasMultipleResourceOptions" [options]="resourceOptions" [display]="{ oneLine: true }" [(ngModel)]="resourceSelection" [required]="true"></app-pg-select>
                        <button class="btn btn-sm btn-primary" (click)="addFilter()">
                            <i class="fa-regular fa-plus"></i>
                        </button>
                    </div>
                </div>
            </div>         
        </div>
    </div>
    <div *ngIf="editOrder" class="row">
        <div class="col">
            <h4 class="my-2">{{ 'pg-filter-editor.section-order' | locTranslate }}</h4>
            <div class="py-2 my-2 border-top border-bottom">
                <div *ngIf="this.status.order != null && this.status.order.length > 0" class="d-flex PGFilterEditor-Order">
                    <div *ngFor="let order of this.status.order" class="PGFilterEditor-Order-Element bg-primary text-white">
                        <span>{{getFieldName(resourceId, order.field)}} {{ 'pg-filter-editor.order-direction-' + order.direction | locTranslate }}</span>
                        <button class="btn btn-link text-white" (click)="removeOrder(order)">
                            <i class="fa-regular fa-times"></i>
                        </button>
                    </div>
                </div>

                <div class="PGFilterEditor-Footer">
                    {{ 'pg-filter-editor.order-by' | locTranslate }}
                    <app-pg-select [options]="configByResource[resourceId].fieldOptions" [display]="{ oneLine: true }" [required]="true"
                        [(ngModel)]="orderSelection.field"></app-pg-select>
                    <app-pg-multistate [(ngModel)]="orderSelection.direction">
                        <state value="asc">
                            <button class="btn btn-primary"><i class="fa-regular fa-sort-amount-up"></i><span>{{ 'pg-filter-editor.order-direction-asc' | locTranslate }}</span></button>
                        </state>
                        <state value="desc">
                            <button class="btn btn-primary"><i class="fa-regular fa-sort-amount-down"></i><span>{{ 'pg-filter-editor.order-direction-desc' | locTranslate }}</span></button>
                        </state>
                    </app-pg-multistate>
                    <button class="btn btn-sm btn-primary" (click)="addOrder()">
                        <i class="fa-regular fa-plus"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!adminMode && hasAdvancedFilters" class="row">
        <div class="PGFilterEditor-Actions col">
            <button class="btn btn-sm btn-primary" (click)="doApplySearch()">
                <i class="fa-regular fa-filter"></i><span>{{ 'pg-filter-editor.apply-filters' | locTranslate }}</span>
            </button>
        </div>
    </div>
</div>
