<div class="PGTasksView" ngbDropdown [autoClose]="false" (openChange)="onOpenChange($event)">
    <button class="btn btn-white" ngbDropdownToggle>
        <i class="fa-regular fa-lg fa-tasks opacity-100 m-0"></i>
        <div class="PGTasksView-Badges">
            <span *ngIf="getUnreadNotificationsCount(); let count" class="badge bg-danger m-0 p-1">{{count}}</span>
        </div>
    </button>
    <div class="container-fluid PGTasksView-Menu p-3 pg-animation-fadein" ngbDropdownMenu>
        <div class="row PGTasksView-Menu-Notifications">
            <div class="col">
                <app-pg-notification-list></app-pg-notification-list>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <hr/>
            </div>
        </div>
        <div class="row PGTasksView-Menu-Completed">
            <div class="col">
                <button *ngIf="getTaskCount('done'); let count" class="btn btn-sm btn-link p-0" (click)="showCompleted = !showCompleted">{{showCompleted ? 'Nascondi' : 'Mostra'}} completati
                    <ng-container *ngIf="!showCompleted"> ({{count}})</ng-container>
                </button>
            </div>
        </div>
        <div *ngIf="areAllTasksHidden()" class="row PGTasksView-Menu-Empty">
            <div class="col">
                <span class="text-muted">Nessun task</span>
            </div>
        </div>
        <div *ngFor="let task of tasks" class="row PGTasksView-Menu-Entry" [appPgAnimations]="isTaskHidden(task) ? 'ExitL': 'EnterL'">
            <div class="col col-6">
                <div title="{{task.name}}{{task.description != null ? ' - ' + task.description : ''}}">
                    <span>{{task.name}}</span>
                    <small *ngIf="task.description != null" class="text-muted">{{task.description}}</small>
                </div>
            </div>
            <div class="col col-6">
                <div class="PGTasksView-Menu-Entry-Progress">
                    <div class="progress">
                        <div class="progress-bar {{getProgressBarClass(task)}}" role="progressbar" [style.width]="getProgressBarWidth(task)">
                        </div>
                    </div>
                    <small *ngIf="task.errorMessage" class="text-danger">{{task.errorMessage}}</small>
                </div>
                <div *ngIf="task.status == 'error' && !doneTasks[task.id]" class="PGTasksView-Menu-Entry-Buttons" appPgAnimations="EnterL">
                    <button class="btn btn-sm btn-link" (click)="hideErrorTask(task)">
                        <i class="fa-regular fa-times"></i>
                    </button>
                    <button *ngIf="!task.errorFatal" class="btn btn-sm btn-link" (click)="redoErrorTask(task)">
                        <i class="fa-regular fa-redo"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
