import { Component, Input, OnChanges } from '@angular/core';
import { ConfigRelation } from '../../models/config.relations.model';
import { DataService, ResourceMode } from '../../services/data.service';
import { NotificationsService } from '../../services/notifications.service';

@Component({
  selector: 'app-pg-resource-association',
  templateUrl: './pg-resource-association.component.html',
  styleUrls: ['./pg-resource-association.component.scss']
})
export class PgResourceAssociationComponent implements OnChanges {

    @Input() resourceId:string;
    @Input() relatedElement:string;
    @Input() relatedResource:string;
    @Input() relationConfig:ConfigRelation;
    @Input() resourceSemantic:string;

    @Input() resourceData:Array<any>;

    @Input() readonly:boolean;

    @Input() resourceMode:ResourceMode;
    @Input() realmFilter:string;

    selectedElementsString:string = null;
    selectedElementsRelationId:{ [id:string]: string } = null;

    isLoading = false;

    constructor(private dataService:DataService, private notificationsService:NotificationsService) { }

    ngOnChanges(): void {
        this.isLoading = true;

        this._loadData().then(() => {
            this.isLoading = false;
        })
    }

    private _loadData(force?:boolean) {
        return new Promise<null>((resolve, reject) => {
            if(!force && this.resourceData != null) {
                this._handleData(this.resourceData)
                resolve(null);
            }
            else {
                this.dataService.getResourceData(this.resourceId, { limit: 5000 }, this.relatedResource, this.relatedElement, this.resourceMode).subscribe((data) => {
                    this._handleData(data)
                    resolve(null);
                }, () => {
                    reject()
                })
            }
        })
    }

    private _handleData(data:Array<any>) {
        this.selectedElementsString = '';
        this.selectedElementsRelationId = {};

        let _selectedElements = [];

        let _joinTableId =  'related_' + this.dataService.getResourceFieldName(this.relationConfig.joinTable)
        let _relatedResourceField = this.dataService.getResourceFieldName(this.relatedResource) + '_id';

        for(let _cData of data) {
            if(_cData.pivot != null) {
                if(this.realmFilter == null || _cData.pivot.realm_id == this.realmFilter) {
                    this.selectedElementsRelationId[_cData.id] = _cData.pivot.id;
                    _selectedElements.push(_cData.id);
                }
            }
            else {
                for(let _cRelation of _cData[_joinTableId]) {
                    if(this.realmFilter == null || _cRelation.realm_id == this.realmFilter) {
                        if(_cRelation[_relatedResourceField] == this.relatedElement) {
                            this.selectedElementsRelationId[_cData.id] = _cRelation.id;
                            _selectedElements.push(_cData.id);
                            break;
                        }
                    }
                }
            }
        }

        _selectedElements.sort();

        this.selectedElementsString = JSON.stringify(_selectedElements)
    }

    isSaving = false;

    onSelectedElementsChange(val:string) {
        let _oldValSplit = (this.selectedElementsString == null || this.selectedElementsString == '') ? [] : JSON.parse(this.selectedElementsString)
        let _newValSplit = (val == null || val == '') ? [] : JSON.parse(val)

        let _toRemove = [];
        let _toAdd = [];

        for(let _cVal of _newValSplit) {
            if(_oldValSplit.indexOf(_cVal) == -1) {
                _toAdd.push(_cVal);
            }
        }

        for(let _cVal of _oldValSplit) {
            if(_newValSplit.indexOf(_cVal) == -1) {
                _toRemove.push(_cVal);
            }
        }

        _newValSplit.sort();
        this.selectedElementsString = JSON.stringify(_newValSplit);

        if(_toRemove.length > 0 || _toAdd.length > 0) {
            this.isSaving = true;

            let _reqNum = 0;

            for(let _cToRemoveVal of _toRemove) {
                let _joinTableId = this.selectedElementsRelationId[_cToRemoveVal];

                if(_joinTableId != null) {
                    _reqNum++;

                    this.dataService.deleteElement(this.relationConfig.joinTable, _joinTableId, this.resourceMode).subscribe(() => {
                        _reqNum--;
                        
                        if(_reqNum == 0) {
                            this._notifyChanges(_toRemove, _toAdd)
                            this._loadData(true).then(() => {
                                this.isSaving = false;
                            })
                        }
                    })
                }
            }

            for(let _cToAddVal of _toAdd) {
                let _postData:any = {}

                if(this.realmFilter != null) {
                    _postData.realm_id = this.realmFilter;
                }

                _postData[this.dataService.getResourceFieldName(this.resourceId) + '_id'] = _cToAddVal;
                _postData[this.dataService.getResourceFieldName(this.relatedResource) + '_id'] = this.relatedElement;

                _reqNum++;
                
                this.dataService.postResourceData(this.relationConfig.joinTable, _postData, this.resourceMode).subscribe((data) => {
                    _reqNum--;

                    if(_reqNum == 0) {
                        this._notifyChanges(_toRemove, _toAdd)
                        this._loadData(true).then(() => {
                            this.isSaving = false;
                        })
                    }
                })
            }
        }
    }

    private _notifyChanges(removed:Array<any>, added:Array<any>) {
        let _notification = '';

        if(removed.length > 0) {
            if(_notification != '') _notification += '<br/>';

            if(removed.length == 1) {
                _notification += 'Elemento rimosso'
            }
            else {
                _notification += removed.length + ' elementi rimossi'
            }
        }

        if(added.length > 0) {
            if(_notification != '') _notification += '<br/>';
            
            if(added.length == 1) {
                _notification += 'Elemento aggiunto'
            }
            else {
                _notification += added.length + ' elementi aggiunti'
            }
        }

        this.notificationsService.addLocalNotification(_notification, 'success', null, null, 3000)
    }
}
