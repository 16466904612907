import { AfterViewChecked, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormFlow, FormFlowState, PgFormLayout } from '../../../models/form.model';
import { PgFormComponent } from '../../../pg-form/pg-form/pg-form.component';
import { DataService } from '../../../services/data.service';
import { LocalizationService } from '../../../services/localization.service';
import { ValidationService } from '../../../pg-ui-controls/validation.service';
import { SaveStatusRequest, SaveStatusService } from '../../../pg-ui-elements/save-status.service';

export class FormsFlowState {
    currentState: string
    stateHistory: Array<string>
    values: any
}

@Component({
  selector: 'app-forms-main',
  templateUrl: './forms-main.component.html',
  styleUrls: ['./forms-main.component.scss']
})
export class FormsMainComponent implements OnInit, OnDestroy, AfterViewChecked {
    @Input() formId:string;
    @Input() formFlow:FormFlow;

    @Input() flowState:any;

    @Input() importData:any;

    @Input() viewOnly:boolean;

    @Input() inModal:boolean;

    @Input() notFound:boolean;

    @Output() flowStateChange = new EventEmitter<FormsFlowState>();
    @Output() valuesChange = new EventEmitter<any>();

    @Output() saveData = new EventEmitter<any>();
    @Output() deleteData = new EventEmitter<any>();
    @Output() cancelEdit = new EventEmitter<any>();
    @Output() resetForm = new EventEmitter<any>();

    openMode:'edit'|'create' = null;

    formSubmitText:string = null;
    formCancelText:string = null;

    multiFormAddSubmitText:string = null;
    multiFormEditSubmitText:string = null;
    multiFormEditCancelText:string = null;

    flowSteps:Array<string> = null;
    flowProgress:number = null;
    flowProgressString:string = null;

    stateHistory:Array<string> = [];

    currentState:string = null;
    currentForm:PgFormLayout = null;
    currentFormMulti:Array<PgFormLayout> = null; // NB: è possibile che questa cosa non serva, da valutare

    values:any = {}

    private _saveRequest:SaveStatusRequest = null;

    constructor(private modalService:NgbModal, private dataService:DataService, private localizationService:LocalizationService, private validationService:ValidationService, private saveStatusService:SaveStatusService) { }

    ngOnInit(): void {
        // TODO: ripulire questa gestione

        this.formSubmitText = '<span>' + this.localizationService.translate('forms-main.next') + '</span><i class="fa-regular fa-chevron-right"></i>'
        this.formCancelText = '<i class="fa-regular fa-chevron-left"></i><span>' + this.localizationService.translate('forms-main.previous') + '</span>'

        this.multiFormAddSubmitText = '<span>' + this.localizationService.translate('forms-main.add') + '</span><i class="fa-regular fa-plus"></i>';
        this.multiFormEditSubmitText = '<span>' + this.localizationService.translate('forms-main.save') + '</span><i class="fa-regular fa-check"></i>';
        this.multiFormEditCancelText = '<span>' + this.localizationService.translate('forms-main.cancel') + '</span><i class="fa-regular fa-undo"></i>';

        if(this.flowState == null) {
            if(this.importData != null) {
                this.values = JSON.parse(JSON.stringify(this.importData));
            }

            if(this.values.id == null || this.dataService.isDraftId(this.values.id)) {
                this.openMode = 'create';
            }
            else {
                this.openMode = 'edit';
            }

            this._checkCreateSaveRequest();

            this._goToState(this.formFlow.entry);

            if(this.viewOnly) {
                while(this.currentState != null) {
                    this.nextState();
                }
            }
        }
        else {
            this.openMode = 'create';

            this._checkCreateSaveRequest();

            if(typeof this.flowState.currentState == 'undefined') {
                if(this.importData != null) {
                    this.values = JSON.parse(JSON.stringify(this.importData));
                }

                for(let i in this.flowState) {
                    if(i != 'id') {
                        this.values[i] = this.flowState[i];
                    }
                }
    
                this._goToState(this.formFlow.entry);
            }
            else {
                try {
                    this.currentState = this.flowState.currentState;
                    this.stateHistory = this.flowState.stateHistory;
                    this.values = this.flowState.values;
    
                    for(let _cState of this.stateHistory) {
                        if(this.formFlow.states[_cState].multi) {
                            this.formFlow.states[_cState].form.resetData();
                        }
                        else {
                            this.formFlow.states[_cState].form.setData(this.values)
                        }
                    }
        
                    this._onStateChange()
                }
                catch(ex) {
                    this.resetForm.emit();
                }
            }
        }
    }

    ngOnDestroy(): void {
        if(this._modalStateMultiEditRef != null) {
            this._modalStateMultiEditRef.dismiss()
        }

        if(this._saveRequest != null) {
            this.saveStatusService.removeRequest(this._saveRequest)
        }
    }

    private _checkCreateSaveRequest() {
        if(!this.viewOnly && !this.inModal) {
            if(this.openMode == 'create') {
                this._saveRequest = this.saveStatusService.createRequest('create', this.localizationService.translate('forms-main.element-' + this.formId + '-new'), (action:string) => {
                    if(action == 'delete') {
                        this.resetForm.emit()
                    }
                    else if(action == 'cancel') {
                        this.cancelEdit.emit()
                    }
                    else if(action == 'previous') {
                        this.previousState();
                    }
                    else if(action == 'next') {
                        this.checkNextState()
                    }
                    else if(action == 'save') {
                        this.checkSaveData()
                    }
                }, 'app-forms-main', { delete: true, cancel: true, previous: false, next: true, save: null })
            }
            else if(this.openMode == 'edit') {
                this._saveRequest = this.saveStatusService.createRequest('edit', this.localizationService.translate('forms-main.element-' + this.formId) + ' - ' + this.values.id, (action:string) => {
                    if(action == 'delete') {
                        this.deleteData.emit()
                    }
                    else if(action == 'cancel') {
                        this.cancelEdit.emit()
                    }
                    else if(action == 'previous') {
                        this.previousState();
                    }
                    else if(action == 'next') {
                        if(this.formFlow.states[this.currentState].multi) {
                            this.nextState()
                        }
                        else {
                            this.stateForm.submitForm()
                        }
                    }
                    else if(action == 'save') {
                        this.checkSaveData()
                    }
                }, 'app-forms-main', { delete: true, cancel: true, previous: false, next: true, save: true })
            }
        }
    }

    hasNextButton() {
        return this.currentState != null;
    }

    hasPreviousButton() {
        return this.currentState == null || (this.currentState != this.formFlow.entry && !this.formFlow.states[this.currentState].noBack)
    }

    private _handleFlowStateChange() {
        if(this._saveRequest != null) {
            if(this.openMode == 'create') {
                if(this.currentState == null) {
                    this._saveRequest.actions.save = true;
                }
                else {
                    this._saveRequest.actions.save = false;
                }
            }

            this._saveRequest.actions.next = this.hasNextButton();
            this._saveRequest.actions.previous = this.hasPreviousButton();
        }

        this.flowStateChange.emit({
            currentState: this.currentState,
            stateHistory: this.stateHistory,
            values: this.values
        })
    }

    @ViewChild('progressCont') progressCont:ElementRef;

    private _alignProgress = false;

    ngAfterViewChecked() {
        if(this._alignProgress && this.progressCont != null) {
            // questo è un po' improprio che sia qui

            try {
                document.querySelector('.PGHeader').scrollIntoView({ behavior: 'smooth' })
            }
            catch(ex) {}

            this._alignProgress = false;

            let _cProgressCont = this.progressCont.nativeElement as HTMLElement;

            if(this.currentState == null) {
                _cProgressCont.scrollTo({
                    top: 0,
                    left: _cProgressCont.scrollWidth - _cProgressCont.offsetWidth,
                    behavior: 'smooth'
                })
            }
            else {
                let _cTargetElement = _cProgressCont.querySelector('[data-state-id=\'' + this.currentState + '\']') as HTMLElement
                if(_cTargetElement != null) {
                    _cProgressCont.scrollTo({
                        top: 0,
                        left: _cTargetElement.offsetLeft - _cTargetElement.offsetWidth,
                        behavior: 'smooth'
                    })
                }
            }
        }
    }

    previousState() {
        this.currentStateMultiEdit = null;
        this.currentState = this.stateHistory.pop();

        this._onStateChange()
    }

    private _goToState(id:string) {
        this.currentState = id;
        this._onStateChange()
    }

    private _getNextState(state:FormFlowState) {
        let _cNext = state.nextState;

        if(typeof _cNext == 'function') {
            return _cNext(this.values);
        }
        else {
            return _cNext;
        }
    }

    nextState() {
        this.currentStateMultiEdit = null;
        if(this.currentState != null) {
            this.stateHistory.push(this.currentState); 
        }

        this._goToState(this._getNextState(this.formFlow.states[this.currentState]));
    }
    
    checkNextState() {
        if(this.formFlow.states[this.currentState].multi) {
            this.nextState()
        }
        else {
            this.stateForm.submitForm()
        }
    }

    private _cloneFormLayout(form:PgFormLayout) {
        let _retVal = new PgFormLayout()

        for(let _field of form.fieldList) {
            _retVal.fieldList.push(JSON.parse(JSON.stringify(_field)))
        }

        _retVal.formGroups = JSON.parse(JSON.stringify(form.formGroups))

        return _retVal;
    }

    private _onStateChange() {
        this._alignProgress = true;

        if(this.currentState != null) {
            this.currentForm = this.formFlow.states[this.currentState].form;
            this.currentFormMulti = [];
            
            let _multi = this.formFlow.states[this.currentState].multi

            if(_multi) {
                this.currentForm.resetData();

                if(this.values != null) {
                    if(this.values[_multi] != null) {
                        for(let i = 0; i < this.values[_multi].length; i++) {
                            this.currentFormMulti[i] = this._cloneFormLayout(this.currentForm)
                            this.currentFormMulti[i].setData(this.values)
                        }
                    }
                }
            }
            else {
                this.currentForm.setData(this.values)
            }
        }

        if(typeof this.formFlow.steps == 'function') {
            this.flowSteps = this.formFlow.steps(this.values);
        }
        else {
            this.flowSteps = this.formFlow.steps;
        }
        
        if(this.flowSteps == null) {
            this.flowProgress = null;
            this.flowProgressString = null;
        }
        else {
            let _cWeightTotal = 0;
            let _cWeightDone = 0;

            for(let i = 0; i < this.flowSteps.length; i++) {
                let _cState = this.formFlow.states[this.flowSteps[i]]
                let _cWeight = 1;
                if(_cState.weight != null) _cWeight = _cState.weight;

                _cWeightTotal += _cWeight;
                if(i < this.stateHistory.length) {
                    _cWeightDone += _cWeight;
                }
            }

            this.flowProgress = _cWeightDone / _cWeightTotal;
            this.flowProgressString = Math.round(this.flowProgress * 100) + '%'
        }

        this._handleFlowStateChange()
    }

    onValueChange() {
        let _cValues = this.currentForm.getData();

        for(let i in _cValues) {
            this.values[i] = _cValues[i];
        }

        this.valuesChange.emit(this.values);
        this._handleFlowStateChange();
    }

    onFormCancel() {
        this.previousState();
    }

    isLoading = false;

    onFormSubmit(values:any) {
        for(let i in values) {
            this.values[i] = values[i];
        }

        this.nextState()
    }

    onMultiFormAddSubmit(values:any) {
        let _multi = this.formFlow.states[this.currentState].multi;

        let _formData = this.currentForm.getData();

        if(this.values[_multi] == null) this.values[_multi] = [];
        this.values[_multi].push(_formData);

        let _formLayout = this._cloneFormLayout(this.currentForm)
        this.currentFormMulti.push(_formLayout)
        _formLayout.setData(_formData)

        this._handleFlowStateChange();

        this.currentForm.resetData();
    }

    multiFormDelete(item:any) {
        let _multi = this.formFlow.states[this.currentState].multi;

        let _index = this.values[_multi].indexOf(item);
        if(_index != -1) {
            this.values[_multi].splice(_index, 1)
            this.currentFormMulti.splice(_index, 1)
        }

        this._handleFlowStateChange();
    }

    multiFormMove(item:any, position:number) {
        let _multi = this.formFlow.states[this.currentState].multi;

        let _index = this.values[_multi].indexOf(item);
        
        if(_index != -1) {
            let _cSwapIndex = _index + position;

            let _cSwapItem = this.values[_multi][_cSwapIndex];
            this.values[_multi][_cSwapIndex] = this.values[_multi][_index]
            this.values[_multi][_index] = _cSwapItem

            this.currentFormMulti[_index].setData(this.values[_multi][_index])
            this.currentFormMulti[_cSwapIndex].setData(this.values[_multi][_cSwapIndex])
        }

        this._handleFlowStateChange();
    }

    @ViewChild('modalStateMultiEdit') modalStateMultiEdit:ElementRef;

    private _modalStateMultiEditRef:NgbModalRef = null;

    currentStateMultiEdit:number = null;
    formLayoutMultiEdit:PgFormLayout = null;

    multiFormAdd() {
        let _item = {
            languages: this.values.languages
        } as any;

        this.multiFormEdit(_item);
    }

    multiFormEdit(item:any) {
        this.currentStateMultiEdit = item;

        this.formLayoutMultiEdit = this._cloneFormLayout(this.currentForm)
        this.formLayoutMultiEdit.setData(item)
        this._modalStateMultiEditRef = this.modalService.open(this.modalStateMultiEdit, { size: 'lg' });
    }

    onMultiFormEditSubmit(values:any) {
        let _multi = this.formFlow.states[this.currentState].multi;

        if(this.values[_multi] == null) this.values[_multi] = [];

        let _index = this.values[_multi].indexOf(this.currentStateMultiEdit);
        if(_index == -1) {
            this.values[_multi].push(this.formLayoutMultiEdit.getData());
            this.currentFormMulti.push(this.formLayoutMultiEdit)
        }
        else {
            this.values[_multi][_index] = this.formLayoutMultiEdit.getData();
            this.currentFormMulti[_index] = this.formLayoutMultiEdit;
        }

        this.currentStateMultiEdit = null;
        this.formLayoutMultiEdit = null;

        this._modalStateMultiEditRef.close();

        this._handleFlowStateChange();
    }

    onMultiFormEditCancel() {
        this._modalStateMultiEditRef.dismiss();

        this.currentStateMultiEdit = null;
        this.formLayoutMultiEdit = null;
    }

    getProgressStepClass(state:string) {
        if(state == this.currentState) return 'FormsMain-Progress-Ticks--Current';
        else if(this.openMode == 'edit') {
            return 'FormsMain-Progress-Ticks--Selectable'
        }
    }

    getProgressStepFlex(state:string) {
        let _retVal = 1;

        if(this.formFlow.states[state].weight != null) _retVal = this.formFlow.states[state].weight; 

        /*if(this.getProgressStepClass(state) == null) {
            _retVal *= 0.25;
        }*/

        return _retVal.toString();
    }

    @ViewChild('stateForm') stateForm:PgFormComponent;

    jumpToState(id:string) {
        if(this.openMode == 'edit') {
            if(this.stateForm == null || this.stateForm.validateForm()) {
                let _historyIndex = null;

                for(let i = 0; i < this.stateHistory.length; i++) {
                    if(this.stateHistory[i] == id) {
                        _historyIndex = i;
                        break;
                    }
                }
                
                if(_historyIndex != null) {
                    this.stateHistory.splice(_historyIndex);
                    this._goToState(id);
                }
                else {
                    while(this.currentState != null && this.currentState != id) {
                        this.nextState();
                    }
                }
            }
        }
    }

    checkSaveData() {
        if(this.stateForm != null) {
            let _isAllValid = true;

            for(let i in this.formFlow.states) {
                if(this.formFlow.states[i].multi) { // TODO: come li valido questi? ciclando tutti i valori con la setData?
                }
                else {
                    this.formFlow.states[i].form.setData(this.values);

                    for(let _field of this.formFlow.states[i].form.fieldList) {
                        if(_field.requiredIf != null) {
                            _field.required = this.formFlow.states[i].form.evaluateCondition(_field.requiredIf, this.values, _field) ? true : false;
                        }
                    }

                    if(this.validationService.validateForm(this.formFlow.states[i].form.fieldList) != null) {
                        _isAllValid = false;
                        this.jumpToState(i)
    
                        setTimeout(() => {
                            this.stateForm.validateForm()
                        }, 100)
                    }
                }
            }

            if(_isAllValid) {
                let _values = this.stateForm.formLayout.getData()

                for(let i in _values) {
                    this.values[i] = _values[i]
                }

                this.saveData.emit(this.values)
            }
        }
        else {
            this.saveData.emit(this.values)
        }
    }
}
