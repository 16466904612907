import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthService } from './auth.service';
import { DataFilter, DataService } from './data.service';
import { EnvironmentService } from './environment.service';
import { LocalizationService } from './localization.service';

export class PGNotificationContent {
    value:string | { [lang:string]: string };
    link?:string;
    context?:{
        resource?:string,
        element?:string,
        data?:any
    }
}

export declare type PGNotificationType = 'user'|'system'
export declare type PGNotificationCategory = 'success'|'warning'|'danger'|'info'

export class PGNotificationData {
    id:string = null;
    type:PGNotificationType = null;
    from:string = null;
    content:string|PGNotificationContent = null;
    status:string = null;
    created_at:string = null;
    updated_at:string = null;
    user_id:string = null;

    has_children?:boolean = null;
    parent?:string = null;

    category?:PGNotificationCategory = null;
}

export class PGNotification extends PGNotificationData {
    content:PGNotificationContent = null;
    time:number = null;
    
    constructor(fromData?:PGNotificationData, public local?:boolean, public duration?:number) {
        super();

        if(fromData != null) {
            for(let i in fromData) {
                if(typeof this[i] != 'undefined') {
                    if(i == 'content' && typeof fromData[i] == 'string') {
                        let _content = fromData[i] as string;

                        try {
                            this.content = JSON.parse(_content);
                        }
                        catch(ex) {
                        }
                    }
                    else {
                        this[i] = fromData[i]
                    }
                }
            }

            if(this.type == 'system') this.category = 'danger';
        }

        if(this.duration != null) this.time = Date.now();
    }
}

@Injectable({
  providedIn: 'root'
})
export class NotificationsService { // TODO: cambiare nome in NotificationService?
    notifications:Array<PGNotification> = []
    onNotification = new Subject<{ operation: 'add'|'del', notification: PGNotification }>()

    unreadCount = 0;

    constructor(private dataService:DataService, private localizationService:LocalizationService, private environmentService:EnvironmentService, private authService:AuthService) { 

    }

    isInitialized = false;

    private _refreshUnreadCount() {
        this.unreadCount = 0;

        for(let _notification of this.notifications) {
            if(!_notification.local && _notification.user_id != null && _notification.status != 'read') {
                this.unreadCount++;
            }
        }
    }

    init() {
        return new Promise<void>((resolve, reject) =>{            
            this._loadNotificationData()

            this.authService.statusChange.subscribe(() => {
                for(let i = this.notifications.length - 1; i >= 0; i --) {
                    if(!this.notifications[i].local) {
                        let _removed = this.notifications.splice(i, 1);
                        this.onNotification.next({ operation: 'del', notification: _removed[0] })
                        this._refreshUnreadCount()
                    }
                }

                this._lastUpdatedAt = null;

                this._loadNotificationData();
            })

            setInterval(() => {
                this._loadNotificationData()
            }, 60000)

            this._handleApplicationStatus();
            this.environmentService.applicationStatusChange.subscribe((data) => {
                this._handleApplicationStatus();
            })

            this.isInitialized = true;
            resolve();
        })
    }

    private _lastUpdatedAt:string = null;

    private _loadNotificationData() {
        if(this.authService.isLoggedIn()) {
            let _notificationFilters:Array<DataFilter> = [];

            _notificationFilters.push({
                field: 'status',
                operator: '!=',
                value: ['read']
            })
    
            if(this._lastUpdatedAt) {
                _notificationFilters.push({
                    field: 'updated_at',
                    operator: '>',
                    value: [this._lastUpdatedAt]
                })
            }
    
            this.dataService.getNotifications({ limit: 1000, filter: _notificationFilters }, null, null, true).subscribe((data) => {
                for(let i = data.length - 1; i >= 0; i--) {
                    if(this._lastUpdatedAt == null || data[i].updated_at > this._lastUpdatedAt) this._lastUpdatedAt = data[i].updated_at;
    
                    let _duration:number = null;
                    
                    if(data[i].deadline != null) {
                        let _deadlineDate = new Date(data[i].deadline);
                        _duration = _deadlineDate.getTime() - Date.now()
                    }

                    if(_duration == null || _duration > 5000) {
                        this._handleNotificationData(data[i], false, _duration)
                    }
                }
            })
        }
    }

    private _lastMaintenanceString = null;

    private _handleApplicationStatus() {
        let _maintenanceString = null;
        if(this.environmentService.applicationStatus.maintenance != null) _maintenanceString = JSON.stringify(this.environmentService.applicationStatus.maintenance)
        
        if(this._lastMaintenanceString != _maintenanceString) {
            this._lastMaintenanceString = _maintenanceString;

            if(this.environmentService.applicationStatus.maintenance != null && this.environmentService.applicationStatus.maintenance.from != null) {

                let _from = this.environmentService.applicationStatus.maintenance.from;

                let _data:any = {
                    from: _from + '|datetime',
                    from_date: _from + '|date',
                    from_time: _from + '|time',
                }

                let _to = this.environmentService.applicationStatus.maintenance.to;

                if(_to != null) {
                    _data.to = _to + '|datetime'
                    _data.to_date = _to + '|date'
                    _data.to_time = _to + '|time'
                }

                this.addLocalNotification({
                    value: 'general.scheduled-maintenance',
                    context: { 
                        data: _data
                    },
                }, 'danger', 'MAINTENANCE', 'MAINTENANCE')
            }
            else {
                this.deleteLocalNotification('MAINTENANCE')
            }
        }
    }

    private _handleNotificationData(data:PGNotificationData, local?:boolean, duration?:number) {
        let _notification = new PGNotification(data, local, duration);

        if(_notification.content != null && _notification.content.context != null && _notification.content.context.data == null && _notification.content.context.resource != null && _notification.content.context.element != null) {
            this.dataService.getElementData(_notification.content.context.resource, _notification.content.context.element).subscribe((data) => {
                _notification.content.context.data = data;
                this._dispatchNotification(_notification)
            })
        }
        else {
            this._dispatchNotification(_notification)
        }

        if(duration != null) {
            setTimeout(() => {
                this.deleteLocalNotification(data.id)
            }, duration)
        }
    }

    private _dispatchNotification(notification:PGNotification) {
        let _wasIn = false;

        for(let i = 0; i < this.notifications.length; i++) {
            if(this.notifications[i].id == notification.id) {
                this.notifications[i] = notification;
                _wasIn = true;
                break;
            }
        }

        if(!_wasIn) {
            this.notifications.unshift(notification)
            this.onNotification.next({ operation: 'add', notification: notification })
            this._refreshUnreadCount()
        }
    }

    deleteLocalNotification(id:string) {
        for(let j = this.notifications.length - 1; j >= 0; j--) {
            if(this.notifications[j].local && this.notifications[j].id == id) {
                let _removed = this.notifications.splice(j, 1);
                this.onNotification.next({ operation: 'del', notification: _removed[0] })
                this._refreshUnreadCount()
                break;
            }
        }
    }

    addLocalNotification(content:PGNotificationContent|string, category?:PGNotificationCategory, from?:string, id?:string, duration?:number) {
        if(id == null) id = Math.random().toString();

        if(typeof content == 'string') {
            content = {
                value: content
            }
        }

        this._handleNotificationData({
            id: 'LOCAL_' + id,
            from: from,
            type: null,
            category: category,
            status: null,
            content: content,
            created_at: null,
            updated_at: null,
            user_id: null,
        }, true, duration)
    }

    markAsRead(list:string|Array<string>) {
        if(typeof list == 'string') list = [list];

        for(let _notification of this.notifications) {
            if(list.indexOf(_notification.id) != -1 && _notification.user_id != null && _notification.status != 'read') {
                _notification.status = 'read';
            }
        }

        this.dataService.markNotificationsAsRead(list).subscribe(() => {
            for(let j = this.notifications.length - 1; j >= 0; j--) {
                if(list.indexOf(this.notifications[j].id) != -1) {
                    let _removed = this.notifications.splice(j, 1);
                    this.onNotification.next({ operation: 'del', notification: _removed[0] })
                    this._refreshUnreadCount()
                }
            }
        }, () => {})
    }

    getLocalizedNotificationContent(notification:PGNotification) {
        if(notification.content != null && typeof notification.content == 'object' && notification.content.value != null) {
            let _value = this.localizationService.getTranslatedValue(notification.content.value);

            let _contextData = null;
            if(notification.content.context != null) _contextData = notification.content.context.data;

            return this.localizationService.translate(_value, _contextData, true);
        }
        else if(typeof notification.content == 'string') {
            return this.localizationService.translate(notification.content);
        }
    }
}
